/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { graphql } from 'gatsby'
import { useEffect, useState } from 'react'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2/ProductGallery'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import type { SearchState } from '@faststore/sdk'
import type { PageProps } from 'gatsby'
import type {
  SearchCollectionPageQuery,
  SearchCollectionPageQueryVariables,
} from '@generated/graphql'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { B2B } from 'src/store-config/B2B-Config'
import { B2C } from 'src/store-config/B2C-Config'
import Seo from 'src/components/seo/Seo'

export type Props = PageProps<
  SearchCollectionPageQuery,
  SearchCollectionPageQueryVariables
>

const useSearchParams = ({ href }: Location) => {
  const [params, setParams] = useState<SearchState | null>(null)

  useEffect(() => {
    const url = new URL(href)

    setParams(parseSearchState(url))
  }, [href])

  return params
}

function Page(props: Props) {
  const {
    data: { site, allCmsCollectionPage },
  } = props

  const searchParams = useSearchParams(props.location)
  const title = 'Search Results | FastStore'

  const isB2B = checkEnviromentIsB2B()

  if (!searchParams) {
    return null
  }

  const collection = allCmsCollectionPage.nodes
    .find((node) => node.name === 'Páginas de Coleção')
    ?.sections[0].data?.contents?.find(
      (item: any) => item.code === searchParams.term
    )

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <Seo
        language="pt-br"
        title={collection?.titlePage ?? site?.siteMetadata?.title}
        description={
          collection?.titlePage ?? site?.siteMetadata?.description ?? ''
        }
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />

      <SROnly as="h1" text={title} />
      <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto ">
        <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto px-4 mb-6">
          <Breadcrumb
            type="single"
            breadcrumbList={[
              {
                item: `/collection?q=${collection?.name ?? searchParams.term}`,
                name: collection?.name ?? searchParams.term ?? '',
                position: 1,
              },
            ]}
          />
        </div>
      </div>

      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}

      {/* <ProductGalleryHeading>
        PRODUTOS PARA COLEÇÃO{'  '}
        <span className="text-blue">
          {collection?.name ?? searchParams.term}
        </span>
      </ProductGalleryHeading> */}

      <ProductGalleryV2
        bannerDesktop={collection?.bannerDesktop}
        bannerMobile={collection?.bannerMobile}
        title="Search Results"
        searchEngine="vtex"
        salesChannel={isB2B ? B2B.salesChannel : B2C.salesChannel}
        type="collection"
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SearchCollectionPage {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
    allCmsCollectionPage {
      nodes {
        id
        name
        sections {
          data
          id
          name
        }
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)
